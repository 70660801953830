export class CoreService {
    'use strict';
    
    static needTripUpdate = false;
    static dialogSizes = [
        {
            id: 'filtersDialog',
            width: 500,
        }
    ];
    
    static debounce(func, timeout = 300){
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }
    
    static getArray(array) {
        return JSON.parse(JSON.stringify(array));
    }
    
    static getGuid() {
        function randomHexDigit() {
            return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        }
        
        // Generate the GUID
        return (
            randomHexDigit() + randomHexDigit() + '-' +
            randomHexDigit() + '-' +
            '4' + randomHexDigit().substr(0, 3) + '-' +
            randomHexDigit().substr(0, 4) + '-' +
            randomHexDigit() + randomHexDigit() + randomHexDigit()
        );
    }
    
    // #region ANIMATE
    
    static scrollToTop(element){
        if(element)
            element.scrollTo({top: 0, behavior: 'smooth'});
        else
        document.querySelector('.container').scrollTo({top: 0, behavior: 'smooth'});
    }
    
    static scrollToBottom(element){
        
        if(element)
            element.scrollTo({top: 1000000, behavior: 'smooth'});
        else
        document.querySelector('.container').scrollTo({top: 1000000, behavior: 'smooth'});
    }
    
    // #endregion
    
    // #region MDC CONTROL
    
    static initTabs() {
        $('md-primary-tab').each((index, tab) => {
            tab = eval(tab.id);
            let tabElement = tab.length > 1 ? tab[1] : tab;
            
            tabElement.addEventListener('click', (event) => {
                let previousTabIdSelected = $(event.target).parent().find('[active]').attr('id');
                $('[aria-labelledby="' + previousTabIdSelected + '"]').removeAttr('active').attr('hidden', 'hidden');
                $('[aria-labelledby="' + event.target.id + '"]').removeAttr('hidden').attr('active', 'active');
            });
        });
    }
    
    static initSliders() {
        $('md-slider').each((index, slider) => {
            slider = eval(slider.id);
            
            // set the label with the value
            setTimeout(() => {
                let sliderLabel = $(slider).parent().find('span');
                sliderLabel.text(sliderLabel.text().split(' : ')[0] + ' : ' + slider.value);
            }, 1000);
            
            slider.addEventListener('change', (event) => {
                // set the label with the value
                let sliderLabel = $(event.target).parent().find('span');
                sliderLabel.text(sliderLabel.text().split(' : ')[0] + ' : ' + event.target.value);
            });
        });
    }
    
    static initDialogs() {
        $('md-dialog').each((index, dialog) => {
            dialog = eval(dialog.id);
            this.setMinWidth(dialog);
            let dialogElement = dialog.length > 1 ? dialog[1] : dialog;
            
            dialogElement.addEventListener('opened', (event) => {
                this.blockDialogheight($(dialog));
            });
            
            // Fix the keyboard issue on mobile
            dialogElement.querySelectorAll('md-outlined-text-field').forEach((textField) => {
                this.handleKeyBoardOpening(textField);
            });
        });
    }
    
    static blockDialogheight(dialog) {
        setTimeout(() => {
            let tabsHeight = dialog[0].querySelector('md-tabs') ? dialog[0].querySelector('md-tabs').clientHeight : 0;
            let minHeight = dialog[0].shadowRoot.querySelector('.scroller').clientHeight - tabsHeight;
            minHeight = minHeight < 300 ? 300 : minHeight;
            dialog.find('#picture-panel .place-picture').css('min-height', minHeight + 'px');
        }, 1000);
    }
    
    static setMinWidth(dialog) {
        let minWidth = 500;
        
        if(this.dialogSizes.map((ds) => ds.id).includes(dialog.id))
            minWidth = this.dialogSizes.filter((ds) => ds.id == dialog.id)[0].width;
        
        minWidth = minWidth > window.outerWidth ? window.outerWidth : minWidth;
        
        $(dialog).css('min-width', minWidth + 'px');
    }
    
    // #endregion
    
    // #region UI
    
    static handleKeyBoardOpening(controlInput, callback = null) {
        controlInput.addEventListener('focus', () => {
            if(!this.isMobile())
                return;
            
            $('nav').hide();
            
            setTimeout(() => {
                if(callback)
                    callback();
            }, 500);
        });
        
        controlInput.addEventListener('focusout', () => {
            setTimeout(() => {
                $('nav').show();
                
                if(callback)
                    callback();
            }, 500);
        });
    }
    
    static isMobile() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        
        if (/android/i.test(userAgent)) {
            return true;
        }
        
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }
        
        return false;
    }
    
    static async preloadImage(src) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', resolve);
            image.addEventListener('error', reject);
            image.src = src;
        });
    }
    
    // #endregion
    
    // #region SLIDER

    static initSlider(selector) {
        if(!CoreService.isMobile()) {
            let slider = document.querySelector(selector);
            let startX;
            let scrollLeft;
            let mouseDownTime = 0;
            
            if(!slider) return;
            // Reset events slider
            slider.removeEventListener('mousedown', () => {});
            slider.removeEventListener('mouseleave', () => {});
            slider.removeEventListener('mouseup', () => {});
            slider.removeEventListener('mousemove', () => {});
            
            slider.addEventListener('mousedown', (e) => {
                this.sliderIsDragging = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
                mouseDownTime = new Date().getTime();
                
                setTimeout(() => {
                    if(this.sliderIsDragging) {
                        slider.classList.add('grabbing');
                    }
                }, 100);
            });
            
            slider.addEventListener('mouseleave', () => {
                this.sliderIsDragging = false;
                slider.classList.remove('grabbing');
                slider.classList.remove('active');
            });
            
            slider.addEventListener('mouseup', (e) => {
                if(new Date().getTime() - mouseDownTime < 100) {
                    this.sliderIsDragging = false;
                    slider.classList.remove('grabbing');
                    slider.classList.remove('active');
                    return;
                }
                
                setTimeout(() => {
                    this.sliderIsDragging = false;
                    slider.classList.remove('grabbing');
                    slider.classList.remove('active');
                }, 100);
            });
            
            slider.addEventListener('mousemove', (e) => {
                if(!this.sliderIsDragging) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 2;
                slider.scrollLeft = scrollLeft - walk;
            });
        }
        
        else {
            // on touch start event on slider item, trigger hover effect
            $(selector + '-item').on('touchstart', function() {
                $(this).addClass('hover');
            });
        }
    }

    // #endregion
    
    // #region LOCAL STORAGE
    
    static getTrip(tripId) {
        return JSON.parse(localStorage.getItem('trips')).filter((trip) => trip.id == tripId).first();
    }
    
    static getTrips() {
        return JSON.parse(localStorage.getItem('trips')) ?? [];
    }
    
    static async saveTrip(trip) {
        this.needTripUpdate = true;
        
        let trips = this.getTrips() ?? [];
        
        if(trips.filter((t) => t.id == trip.id).length > 0) {
            trips = trips.map((t) => t.id == trip.id ? trip : t);
        }
        else {
            trips.push(trip);
        }
        
        localStorage.setItem('trips', JSON.stringify(trips));
    }
    
    static setSelectedTripId(tripId) {
        if(tripId == null) {
            localStorage.removeItem('selectedTrip');
        }
        else {
            localStorage.setItem('selectedTrip', tripId);
        }
    }
    
    static getSelectedTripId() {
        return localStorage.getItem('selectedTrip');
    }
    
    // #endregion
}

window.CoreService = CoreService;

Array.prototype.first = function () {
    return (this.length > 0) ? this[0] : null;
};
Array.prototype.last = function () {
    return this[this.length - 1];
};
Array.prototype.insert = function ( index, ...items ) {
    this.splice( index, 0, ...items );
};