<template>
    <div class="trip">
        <div v-if="tripId">
            <nav class="top">
                <div class="header">
                    <h2 class="ellipsis">
                        <md-icon @click="closeTrip">arrow_back_ios_new</md-icon>
                        {{ trip.name }}
                        
                        <span v-if="trip.days.length > 0" class="trip-infos ellipsis">
                            <span>
                                <md-icon>light_mode</md-icon>
                                {{ trip.days.length + (trip.days.length > 1 ? ' days' : ' day') }}
                            </span>
                            <span v-if="trip.nights.length > 0">
                                <md-icon>nights_stay</md-icon>
                                {{ trip.nights.length + (trip.nights.length > 1 ? ' nights' : ' night') }}
                            </span>
                            <span>
                                <md-icon>checklist</md-icon>
                                {{ trip.days.flat().length + (trip.days.flat().length > 1 ? ' steps' : ' step') }}
                            </span>
                        </span>
                    </h2>
                    <div class="nav-actions">                       
                        <md-text-button id="optimizeButton" @click="optimizeStepsByDistance">
                            <md-icon slot="icon">architecture</md-icon>
                        </md-text-button>
                        
                        <md-text-button id="undoStepsButton" @click="undoSteps" disabled>
                            <md-icon slot="icon">undo</md-icon>
                        </md-text-button>
                        
                        <md-text-button id="saveStepsButton" @click="saveSteps" disabled>
                            <md-icon slot="icon">save</md-icon>
                        </md-text-button>
                    </div>
                </div>
                
                <md-tabs id="tripTabs">
                    <md-primary-tab id="stepsTab" aria-controls="stepPanel">
                        <md-icon slot="icon">list</md-icon> Days
                    </md-primary-tab>
                    <md-primary-tab id="mapTab" aria-controls="mapPanel">
                        <md-icon slot="icon">map</md-icon> Map
                    </md-primary-tab>
                    <md-primary-tab id="settingsTab" aria-controls="settingsPanel">
                        <md-icon slot="icon">tune</md-icon> Settings
                    </md-primary-tab>
                </md-tabs>
            </nav>
            
            <div id="stepsPanel" role="tabpanel" aria-labelledby="stepsTab" active>
                <div class="departure-container">
                    <div v-if="!trip.departure">
                        <div class="flex-container">
                            <md-filled-tonal-button @click="addDeparture">
                                <span>Add a start</span>
                                <md-icon slot="icon">play_arrow</md-icon>
                            </md-filled-tonal-button>
                        </div>
                        
                        <md-divider class="small"></md-divider>
                    </div>
                    
                    <div class="departure" v-if="trip.departure">
                        <div class="departure-header">
                            <h3>
                                <md-icon>play_arrow</md-icon>
                                <span>Start</span>
                            </h3>
                        </div>
                        <md-list>
                            <div class="md-drag-item">
                                <md-list-item type="text" class="ellipsis">
                                    <md-icon slot="start">play_arrow</md-icon>
                                    <div slot="headline" class="ellipsis">{{ getInternationalPlaceName(trip.departure.place) }}</div>
                                    <div slot="supporting-text" class="ellipsis">{{ trip.departure.place.address_line2 }}</div>
                                    <md-icon slot="end" @click="deleteDeparture()">delete</md-icon>
                                </md-list-item>
                                <div v-if="trip.departure.timeToNext != null || trip.departure.distanceToNext != null" class="travel-optimization-infos-end">
                                    <!-- Travel optimization infos to next step -->
                                    <span class="travel-dash"></span>
                                    <span v-if="trip.departure.timeToNext != null">
                                        <md-icon>timer</md-icon>
                                        {{ trip.departure.timeToNextFormatted }}
                                    </span>
                                    <span v-if="trip.departure.distanceToNext != null">
                                        <md-icon>distance</md-icon> 
                                        {{ trip.departure.distanceToNextFormatted }}
                                    </span>
                                </div>
                            </div>
                        </md-list>
                    </div>
                </div>
                
                <div v-for="(day, index) in trip.days" :key="day" class="day">
                    <div class="day-header">
                        <h3>
                            <md-icon>light_mode</md-icon>
                            <span>Day {{ index + 1 }}</span>
                            
                            <small v-if="day.length > 0" class="ellipsis">
                                <span>{{ day.length + ' steps' }}</span>
                                <span v-if="getDayTravelTime(day) != '0 min'">
                                    <md-icon>timer</md-icon>
                                    {{ getDayTravelTime(day) }}
                                </span>
                                <span v-if="getDayTravelDistance(day) != '0 m'">
                                    <md-icon>distance</md-icon>
                                    {{ getDayTravelDistance(day) }}
                                </span>
                            </small>
                        </h3>
                        
                        <md-icon @click="removeDay(index)">delete_sweep</md-icon>
                    </div>
                    
                    <md-list :data-day-index="index">
                        <draggable 
                        group="group"
                        :list="trip.days[index]"
                        itemKey="place.place_id"
                        handle=".step-handle"
                        ghostClass="sortable-ghost"
                        chosenClass="sortable-chosen"
                        dragClass="sortable-drag"
                        delayOnTouchOnly="true"
                        animation="250"
                        easing="cubic-bezier(1, 0, 0, 1)"
                        delay="200"
                        @start="dragStart"
                        @end="dragEnd">
                        <template #item="{element}">
                            <div class="md-drag-item" :data-day-index="index" :data-step-index="element.index">
                                <div v-if="trip.days[index][element.index - 1] != null && (trip.days[index][element.index - 1].timeToNext != null || trip.days[index][element.index - 1].distanceToNext != null)" class="travel-optimization-infos">
                                    <!-- Travel optimization infos to next step -->
                                    <span class="travel-dash"></span>
                                    <span v-if="trip.days[index][element.index - 1].timeToNext != null">
                                        <md-icon>timer</md-icon>
                                        {{ trip.days[index][element.index - 1].timeToNextFormatted }}
                                    </span>
                                    <span v-if="trip.days[index][element.index - 1].distanceToNext != null">
                                        <md-icon>distance</md-icon> 
                                        {{ trip.days[index][element.index - 1].distanceToNextFormatted }}
                                    </span>
                                </div>
                                <div slot="start" class="step-handle">
                                    <md-icon>drag_handle</md-icon>
                                </div>
                                <md-list-item type="text" class="ellipsis">
                                    <div slot="start" class="step-number">
                                        <div class="step-number-background"></div>
                                        <div class="step-number-text">{{ element.index + 1 }}</div>
                                    </div>
                                    <md-icon slot="start" >{{ element.marker.icon ?? 'step' }}</md-icon>
                                    <div slot="headline" class="ellipsis">{{ !element.isCrossingPoint ? element.place.name : element.crossingPoint.name }}</div>
                                    <div slot="supporting-text" class="ellipsis">{{ !element.isCrossingPoint ? element.place.address_line2 : element.crossingPoint.display_name }}</div>
                                    
                                    <md-icon slot="end" class="step-menu-button" :id="'step-menu-anchor-' + index + '-' + element.index">more_vert</md-icon>
                                    <md-menu :id="'step-menu-' + index + '-' + element.index" :anchor="'step-menu-anchor-' + index + '-' + element.index" positioning="popover">
                                        <md-menu-item @click="addStepFile(element.index, index)" v-if="!element.isCrossingPoint && !element.file">
                                            <md-icon>description</md-icon>
                                            <span>Add file</span>
                                        </md-menu-item>
                                        <md-menu-item @click="viewStepFile(element.index, index)" v-if="!element.isCrossingPoint && element.file">
                                            <md-icon>description</md-icon>
                                            <span>View file</span>
                                        </md-menu-item>
                                        <md-menu-item @click="editStep(element.index, index)" v-if="!element.isCrossingPoint">
                                            <md-icon>edit</md-icon>
                                            <span>Edit</span>
                                        </md-menu-item>
                                        <md-menu-item @click="deleteStep(element.index, index)">
                                            <md-icon>delete</md-icon>
                                            <span>Delete</span>
                                        </md-menu-item>
                                    </md-menu>
                                </md-list-item>
                            </div>
                        </template>
                    </draggable>
                </md-list>
                
                <div>
                    <div class="flex-container">
                        <md-filled-tonal-button @click="goToExploreMap" v-if="trip.days.length > 0">
                            <span>Add a step</span>
                            <md-icon slot="icon">location_on</md-icon>
                        </md-filled-tonal-button>
                        
                        <md-filled-tonal-button @click="addNight(index)"  v-if="!getNightByDayIndex(index) && day.length > 0">
                            <span>Add a night</span>
                            <md-icon slot="icon">nights_stay</md-icon>
                        </md-filled-tonal-button>
                    </div>
                    
                    <md-divider class="small"></md-divider>
                </div>
                
                <div class="night-container" v-if="getNightByDayIndex(index)">
                    <div class="night">
                        <div class="night-header">
                            <h3>
                                <md-icon>nights_stay</md-icon>
                                <span>Night {{ index + 1 }}</span>
                            </h3>
                        </div>
                        <md-list>
                            <div class="md-drag-item">
                                <md-list-item type="text" class="ellipsis">
                                    <md-icon slot="start">{{ getNightByDayIndex(index).marker.icon }}</md-icon>
                                    <div slot="headline" class="ellipsis">{{ getInternationalPlaceName(getNightByDayIndex(index).place) }}</div>
                                    <div slot="supporting-text" class="ellipsis">{{ getNightByDayIndex(index).place.address_line2 }}</div>
                                    <md-icon slot="end" @click="deleteNight(index)">delete</md-icon>
                                </md-list-item>
                                <div v-if="day.last() && (day.last().timeToNext != null || day.last().distanceToNext != null)" class="travel-optimization-infos-start">
                                    <!-- Travel optimization infos to next step -->
                                    <span class="travel-dash"></span>
                                    <span v-if="day.last().timeToNext != null">
                                        <md-icon>timer</md-icon>
                                        {{ day.last().timeToNextFormatted }}
                                    </span>
                                    <span v-if="day.last().distanceToNext != null">
                                        <md-icon>distance</md-icon> 
                                        {{ day.last().distanceToNextFormatted }}
                                    </span>
                                </div>
                            </div>
                        </md-list>
                    </div>
                    
                    <md-divider class="small"></md-divider>
                </div>
            </div>
            
            <div>
                <div class="flex-container">
                    <md-filled-tonal-button @click="addNewDay" v-if="lastDayHasSteps() || trip.days.length == 0">
                        <span>Add a day</span>
                        <md-icon slot="icon">light_mode</md-icon>
                    </md-filled-tonal-button>
                    
                    <md-filled-tonal-button @click="addArrival" v-if="!trip.arrival">
                        <span>Add a end</span>
                        <md-icon slot="icon">stop_circle</md-icon>
                    </md-filled-tonal-button>
                </div>
            </div>
            
            <div class="arrival-container">
                <div class="arrival" v-if="trip.arrival">
                    <div class="arrival-header">
                        <h3>
                            <md-icon>stop_circle</md-icon>
                            <span>End</span>
                        </h3>
                    </div>
                    <md-list>
                        <div class="md-drag-item">
                            <md-list-item type="text" class="ellipsis">
                                <md-icon slot="start">stop</md-icon>
                                <div slot="headline" class="ellipsis">{{ getInternationalPlaceName(trip.arrival.place) }}</div>
                                <div slot="supporting-text" class="ellipsis">{{ trip.arrival.place.address_line2 }}</div>
                                <md-icon slot="end" @click="deleteArrival()">delete</md-icon>
                            </md-list-item>
                            <div v-if="trip.days.last() && trip.days.last().length > 0 && (trip.days.last().last().timeToNext != null || trip.days.last().last().distanceToNext != null)" class="travel-optimization-infos-start">
                                <!-- Travel optimization infos to next step -->
                                <span class="travel-dash"></span>
                                <span v-if="trip.days.last().last().timeToNext != null">
                                    <md-icon>timer</md-icon>
                                    {{ trip.days.last().last().timeToNextFormatted }}
                                </span>
                                <span v-if="trip.days.last().last().distanceToNext != null">
                                    <md-icon>distance</md-icon> 
                                    {{ trip.days.last().last().distanceToNextFormatted }}
                                </span>
                            </div>
                        </div>
                    </md-list>
                </div>
            </div>
        </div>
        
        <div id="mapPanel" role="tabpanel" aria-labelledby="mapTab" hidden>
            <FinderMap mode="trip" :tripSteps="tripSteps" />
        </div>
        
        <div id="settingsPanel" role="tabpanel" aria-labelledby="settingsTab" hidden>
            <div class="settings-panel">
                <md-outlined-text-field id="tripNameTextField" label="Name" :value="trip.name" type="text" autocomplete="off"></md-outlined-text-field>
                
                <div class="md-slider-container">
                    <span slot="label">Default day step length</span>
                    <md-slider id="tripDefaultDayStepLengthSlider" min="1" max="10" :value="trip.defaultDayStepLength" labeled></md-slider>
                </div>
                
                <div class="md-radio-container" role="radiogroup" aria-labelledby="optimization-group-title">
                    <span slot="label" id="optimization-group-title">Optimization by :</span>
                    
                    <div class="md-radio-item">
                        <md-radio id="timeRadio" name="optimizationMode" value="time" :checked="trip.optimizationMode == 'time'"></md-radio>
                        <label for="timeRadio">Time</label>
                    </div>
                    <div class="md-radio-item">
                        <md-radio id="distanceRadio" name="optimizationMode" value="distance" :checked="trip.optimizationMode == 'distance'"></md-radio>
                        <label for="distanceRadio">Distance</label>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="optimization-overlay">
            <div class="optimization-infos">
                <span class="optimization-infos-step">Optimizing your trip...</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 200">
                    <!-- Définition du dégradé linéaire pour la route -->
                    <defs>
                        <linearGradient id="roadGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" style="stop-color:#3876b1;stop-opacity:1" />
                            <stop offset="100%" style="stop-color:#c25593;stop-opacity:1" />
                        </linearGradient>
                    </defs>
                    
                    <!-- Ligne ondulée représentant la route -->
                    <path id="road" d="M 0 100 Q 100 75, 200 100 T 400 100" fill="transparent" stroke="url(#roadGradient)" stroke-width="6" stroke-dasharray="800" stroke-dashoffset="800">
                        <animate  attributeName="stroke-dashoffset" from="800" to="0" dur="4s" repeatCount="indefinite" />
                        <animate  attributeName="d" dur="4s" keySplines="0.42 0 0.58 1; 0.42 0 0.58 1; 0.42 0 0.58 1" keyTimes="0;0.33;0.66;1" calcMode="spline" repeatCount="indefinite"
                        values="
                    M 0 100 Q 100 75, 200 100 T 400 100;
                    M 0 100 Q 100 125, 200 75 T 400 125;
                    M 0 100 Q 100 50, 200 150 T 400 50;
                    M 0 100 Q 100 100, 200 100 T 400 100" 
                        />
                    </path>
                </svg>
                
            </div>
        </div>
        
        <md-dialog id="addStepFileDialog">
            <div slot="headline">Add files to step</div>
            <form slot="content" id="addStepFileForm" method="dialog">
                <input type="file" @change="handleStepFileUpload">
            </form>
            <div slot="actions">
                <md-text-button form="addStepFileForm" value="cancel">Cancel</md-text-button>
                <md-text-button form="addStepFileForm" value="ok">Add</md-text-button>
            </div>
        </md-dialog>
    </div>
    
    <div v-if="!tripId">
        <Trips />
    </div>
</div>

</template>

<script>
import axios from 'axios';

import Draggable from 'vuedraggable';
import FinderMap from '@/components/FinderMap.vue';
import Trips from '@/components/Trips.vue';

export default {
    name: 'Trip',
    inject: ['store'],
    components: {
        Draggable,
        FinderMap,
        Trips,
    },
    props: ['tripId', 'stepToAdd'],
    data() {
        return {
            flatSteps: [],
            trip: {
                days: [],
                nights: [],
                optimized: false,
            },
            stepFilesInfos: {},
            
            dragging: false,
            defaultDayStepLength: 5,
            optimizationMode: 'time',
            martrixOriginalSources: [],
            tripSteps: [],
            optimized: false,
            
            worker: new Worker('/assets/js/workers/pathWorker.js'),
        }
    },
    setup() {
    },
    beforeRouteLeave(to, from) {
        if(this != null && this.tripId && this.tripId != null && this.tripId.length > 0)
        stepsTab.click();
    },
    mounted() {
        if(this != null && this.tripId && this.tripId != null && this.tripId.length > 0) {
            this.initTrip();
        }
        else {
            CoreService.initSliders();
        }
    },
    updated() {
        this.initStepMenu();
    },
    watch: {
        stepToAdd: function (value, oldValue) {
            if(typeof value == 'string' && value.length > 0)
            this.addStepFromMap(JSON.parse(this.stepToAdd), false);
        },
        tripId: function (value, oldValue) {
            if(value && value.length > 0) {
                this.initTrip();
            }
        },
        optimized: function (value, oldValue) {
            setTimeout(() => {
                if(value) {
                    optimizeButton.disabled = true;
                    this.trip.optimized = true;
                }
                else {
                    optimizeButton.disabled = false;
                    this.trip.optimized = false;
                }
            }, 1000);
        }
    },
    methods: {
        async initTrip() {
            this.trip = await this.$root.getTripFromApi(this.tripId);
            
            // migration of missing properties
            if(this.trip['nights'] == null) {
                this.trip['nights'] = [];
            }
            
            this.defaultDayStepLength = this.trip.defaultDayStepLength ?? this.defaultDayStepLength;
            this.optimizationMode = this.trip.optimizationMode ?? this.optimizationMode;
            this.optimized = this.trip.optimized ?? this.optimized;
            
            this.setInternationalPlaceName(this.trip.days);
            this.checkFirstStepAdded();
            
            setTimeout(() => {
                this.sendTripMarkersToMap();
            }, 1000);
        },
        
        closeTrip() {
            CoreService.setSelectedTripId(null);
            this.$router.push({ name: 'trip' });
        },
        
        // #region SAVE
        
        allowSave() {
            undoStepsButton.disabled = false;
            saveStepsButton.disabled = false;
            
            saveStepsButton.classList.add('bounce');
            
            setTimeout(() => {
                if(typeof saveStepsButton != 'undefined' && saveStepsButton) {
                    saveStepsButton.classList.remove('bounce');
                }
            }, 15000);
        },
        
        denySave() {
            optimizeButton.disabled = this.optimized;
            undoStepsButton.disabled = true;
            saveStepsButton.disabled = true;
            
            saveStepsButton.classList.remove('bounce');
        },
        
        // #endregion
        
        // #region STEPS
        
        checkFirstStepAdded() {
            if(typeof this.stepToAdd == 'string' && this.stepToAdd.length > 0)
            this.addStepFromMap(JSON.parse(this.stepToAdd));
        },
        
        getStoredSteps() {
            if(localStorage['trips'])
            return CoreService.getTrip(this.tripId);
            
            return this.trip;
        },
        
        saveStoredSteps() {
            console.log('saveStoredSteps', this.trip);
            CoreService.saveTrip(this.trip);
            this.$root.saveTripToApi(this.trip);
            this.denySave();
        },
        
        addStepFromMap(newStep, addStepEvenExist) {
            // Departure step
            if(newStep.isDeparture) {
                this.trip.departure = newStep;
            }
            
            // Arrival step
            else if(newStep.isArrival) {
                this.trip.arrival = newStep;
            }
            
            // Arrival step
            else if(newStep.isNight) {
                this.trip.nights.push(newStep);
            }
            
            // Classic step / crossing point
            else {
                let lastDayIndex = this.trip.days?.length - 1;
                lastDayIndex = this.trip.days.length > 0 && this.trip.days[lastDayIndex].length < this.defaultDayStepLength ? lastDayIndex : -1;
                let stepIndex = lastDayIndex < 0 ? 0 : this.trip.days[lastDayIndex].length;
                
                newStep['index'] = stepIndex;
                
                this.dispatchStepInDays(newStep);
                
                setTimeout(() => {
                    this.highlightStep('last');
                }, 100);
            }
            
            this.optimized = false;
            this.saveStoredSteps();
            // reset route params
            this.$router.push({ name: 'trip', params: { tripId: this.tripId } });
        },
        
        getFlatSteps(withStartEndAndNights = false) {
            let daySteps = Array.from(this.trip.days).map(d => d);
            let flatSteps = [];
            
            if(withStartEndAndNights && this.trip.departure) {
                flatSteps.push(this.trip.departure);
            }
            
            for(var i = 0; i < daySteps.length; i++) {
                let currentDaySteps = daySteps[i];
                
                for(var j = 0; j < currentDaySteps.length; j++)
                flatSteps.push(currentDaySteps[j]);
                
                // Add night
                if(withStartEndAndNights && this.trip.nights.filter(n => n.dayIndex == i).length > 0) {
                    flatSteps.push(this.trip.nights.filter(n => n.dayIndex == i).first());
                }
            }
            
            if(withStartEndAndNights && this.trip.arrival) {
                flatSteps.push(this.trip.arrival);
            }
            
            return flatSteps;
        },
        
        saveSteps() {
            this.denySave();
            this.saveStoredSteps();
        },
        
        undoSteps() {
            this.trip = this.getStoredSteps();
            
            this.denySave();
            
            setTimeout(() => {
                this.sendTripMarkersToMap();
            }, 1000);
        },
        
        initStepMenu() {
            for(var i = 0; i < this.trip.days.length; i++) {
                for(var j = 0; j < this.trip.days[i].length; j++) {
                    let stepMenu = document.getElementById('step-menu-' + i + '-' + j);
                    let stepMenuAnchor = document.getElementById('step-menu-anchor-' + i + '-' + j);
                    
                    if(stepMenu && stepMenuAnchor) {
                        $(stepMenuAnchor).unbind('click');
                        $(stepMenuAnchor).click(() => {
                            stepMenu.open = !stepMenu.open;
                        });
                    }
                }
            }
        },
        
        // #region STEP MENU ACTIONS
        
        deleteStep(stepIndex, dayIndex) {
            this.trip.days[dayIndex].splice(stepIndex, 1);
            this.updateStepsIndex();
            
            this.optimized = false;
            this.allowSave();
        },
        
        editStep(stepIndex, dayIndex) {
            alert('TODO: Edit step ' + stepIndex + ' in day ' + dayIndex);
        },
        
        // #region STEP FILES
        
        addStepFile(stepIndex, dayIndex) {
            let step = this.trip.days[dayIndex][stepIndex];
            this.stepFilesInfos = {
                tripId: this.tripId,
                stepIndex: stepIndex,
                dayIndex: dayIndex,
                step: step,
                file: null,
            };
            
            addStepFileDialog.querySelector('[slot="headline"]').textContent = 'Add files to ' + step.place.name;
            addStepFileDialog.show();
        },
        
        handleStepFileUpload(event) {
            this.stepFilesInfos.file = event.target.files[0];
            this.uploadStepFile();
        },
        
        async uploadStepFile() {
            this.$root.addStepFileToApi(this.stepFilesInfos);
        },
        
        async viewStepFile(stepIndex, dayIndex) {
            try {
                let step = this.trip.days[dayIndex][stepIndex];
                let response = await this.$root.getStepFileFromApi(this.tripId, dayIndex, stepIndex);
                
                if (response) {
                    const url = window.URL.createObjectURL(response);
                    
                    const link = document.createElement('a');
                    link.href = url;
                    
                    let filename = step.file.originalname;
                    link.download = filename;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                } else {
                    console.error('La réponse de l\'API est indéfinie');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération du fichier :', error);
            }
        },
        
        // #endregion
        
        // #endregion
        
        // #endregion
        
        // #region DAYS / NIGHTS
        
        dispatchStepInDays(newStep, dayIndex = -1) {
            let lastDayIndex = this.trip.days?.length - 1;
            
            // Check if day index is specified by optimization
            if(dayIndex >= 0) {
                lastDayIndex = dayIndex;
                
                // Create array if not exists for day index
                if(!this.trip.days[lastDayIndex]) {
                    this.trip.days[lastDayIndex] = [];
                }
            }
            
            // STEPS
            if(CoreService.getArray(this.trip.days).length > 0) {
                // LAST DAY IS FULL
                if(this.trip.days[lastDayIndex].length >= this.defaultDayStepLength && dayIndex == -1) {
                    newStep.index = 0;
                    this.trip.days.push([newStep]);
                }
                
                // LAST DAY IS NOT FULL or DAY INDEX SPECIFIED
                else {
                    newStep.index = this.trip.days[lastDayIndex].length;
                    this.trip.days[lastDayIndex].push(newStep);
                }
            }
            
            // NO STEPS
            else {
                newStep.index = 0;
                this.trip.days.push([newStep]);
            }
        },
        
        addNewDay() {
            this.trip.days.push([]);
            this.allowSave();
        },
        
        removeDay(dayIndex) {
            this.trip.days.splice(dayIndex, 1);
            this.updateStepsIndex();
            this.allowSave();
        },
        
        lastDayHasSteps() {
            return this.trip.days.length > 0 && this.trip.days[this.trip.days.length - 1].length > 0;
        },
        
        getDayTravelTime(day) {
            let timeToNext = 0;
            
            for(var i = 0; i < day.length; i++) {
                let step = day[i];
                timeToNext += step.timeToNext ?? 0;
            }
            
            if(timeToNext >= 60) {
                let hours = Math.floor(timeToNext / 60);
                let minutes = timeToNext % 60;
                return hours + 'h ' + minutes + 'min';
            }
            
            return timeToNext + ' min';
        },
        
        getDayTravelDistance(day) {
            let distanceToNext = 0;
            
            for(var i = 0; i < day.length; i++) {
                let step = day[i];
                distanceToNext += step.distanceToNext ?? 0;
            }
            
            if(distanceToNext < 1) {
                return (distanceToNext * 1000) + ' m';
            }
            
            return distanceToNext.toFixed(2) + ' km';
        },
        
        addNight(dayIndex) {
            this.$router.push({ name: 'map', params: { 
                mode: 'explore',
                departureNeeded: false,
                arrivalNeeded: false,
                nightNeeded: dayIndex,
            }});
        },
        
        deleteNight(dayIndex) {
            this.trip.nights = this.trip.nights.filter(n => n.dayIndex != dayIndex);
            
            this.optimized = false;
            this.allowSave();
        },
        
        getNightByDayIndex(dayIndex) {
            if(this.trip.nights.filter(n => n.dayIndex == dayIndex).length == 0) {
                return null;
            }
            
            return this.trip.nights.filter(n => n.dayIndex == dayIndex).first();
        },
        
        // #endregion
        
        // #region DRAG DROP
        
        checkMove(e) {
            // window.console.log("Future index: " + e.draggedContext.futureIndex);
        },
        
        dragStart() {
            this.dragging = true;
            this.hideOptimizationInfos(0);
        },
        
        dragEnd(e) {
            this.dragging = false;
            this.updateStepsIndex();
            
            let dayFromIndex = Number($(e.from).parent().attr('data-day-index'));
            let dayToIndex = Number($(e.to).parent().attr('data-day-index'));
            
            this.removeOptimizationInfos(dayFromIndex);
            this.removeOptimizationInfos(dayToIndex);
            
            this.optimized = false;
            this.allowSave();
            
            this.showOptimizationInfos();
        },
        
        updateStepsIndex() {
            for(var i = 0; i < this.trip.days.length; i++) {
                let currentDay = this.trip.days[i];
                
                for(var j = 0; j < currentDay.length; j++)
                currentDay[j].index = j;
            }
            
            console.log('updateStepsIndex', this.trip);
            this.sendTripMarkersToMap();
        },
        
        highlightStep(stepPosition) {
            let step;
            
            switch(stepPosition) {
                case 'last':
                step = $('.trip md-list md-list-item:last');
                break;
            }
            
            if(step) {
                step.addClass('sortable-chosen');
                CoreService.scrollToBottom();
                
                setTimeout(() => {
                    step.removeClass('sortable-chosen');
                }, 1500);
            }
        },
        
        // #endregion
        
        // #region OPTIMIZATION
        
        showOptimizationOverlay() {
            $('.optimization-overlay').fadeIn();
        },
        
        hideOptimizationOverlay() {
            $('.optimization-overlay').fadeOut();
            this.$root.toastMessage('Optimization done, save it before leaving !', 'success');
        },
        
        async optimizeStepsByDistance() {
            this.showOptimizationOverlay();
            this.flatSteps = this.getFlatSteps(true);
            
            // Reset travel times and distances
            this.flatSteps.forEach(step => {
                delete step.timeToNext;
                delete step.distanceToNext;
            });
            
            let optimalRoutes = [];
            
            // for each day, get all steps
            for(var k = 0; k < this.trip.days.length; k++) {
                let matrixOriginalSources = [];
                let daySteps = this.trip.days[k];
                
                // Push departure step if first day
                if(k == 0 && this.trip.departure) {
                    daySteps.unshift(this.trip.departure);
                }
                
                // Push arrival step if last day
                if(k == this.trip.days.length - 1 && this.trip.arrival) {
                    daySteps.push(this.trip.arrival);
                }
                
                // Push night step if exists
                if(this.trip.nights.filter(n => n.dayIndex == k).length > 0) {
                    daySteps.push(this.trip.nights.filter(n => n.dayIndex == k).first());
                }
                
                for(var l = 0; l < daySteps.length; l++) {
                    let step = daySteps[l];
                    let originalSource;
                    
                    if(step.place) {
                        originalSource = { 
                            location: [step.place.lon, step.place.lat],
                            original_location: [step.place.lon, step.place.lat],
                            isDeparture: step.isDeparture,
                            isArrival: step.isArrival,
                            isNight: step.isNight,
                        };
                    }
                    
                    else {
                        originalSource = { 
                            location: [step.crossingPoint.lon, step.crossingPoint.lat],
                            original_location: [step.crossingPoint.lon, step.crossingPoint.lat],
                            isDeparture: step.isDeparture,
                            isArrival: step.isArrival,
                            isNight: step.isNight,
                        };
                    }
                    
                    matrixOriginalSources.push(originalSource);
                }
                
                let data = {
                    sources: matrixOriginalSources,
                    targets: matrixOriginalSources,
                    sources_to_targets: [],
                    dayIndex: k,
                };
                
                // for each step, get distance to go to all other steps without API as matrix
                for (let i = 0; i < daySteps.length; i++) {
                    // Calculate the distance between step1 and step2 using lat and lon in km
                    let matrix = [];
                    
                    for (let j = 0; j < matrixOriginalSources.length; j++) {
                        const target = matrixOriginalSources[j];
                        let distance = Math.sqrt((target.location[1] - matrixOriginalSources[i].location[1]) ** 2 + (target.location[0] - matrixOriginalSources[i].location[0]) ** 2) * 111.32;
                        matrix.push({ 
                            distance: distance,
                            time: distance / 50 * 60,
                            source_index: i,
                            target_index: j,
                            isDeparture: target.isDeparture,
                            isArrival: target.isArrival,
                            isNight: target.isNight,
                        });
                    }
                    
                    data.sources_to_targets.push(matrix);
                }
                
                optimalRoutes.push(await this.findOptimalPathAsync(data));
            }
            
            this.buildOptimalRoute(optimalRoutes);
        },
        
        buildOptimalRoute(optimalRoutes) {
            this.trip.days = [];
            this.trip.nights = [];
            
            for(let routeIndex = 0; routeIndex < optimalRoutes.length; routeIndex++) {
                const route = optimalRoutes[routeIndex].paths;
                
                for(let pathIndex = 0; pathIndex < route.length; pathIndex++) {
                    const path = route[pathIndex];
                    let step = CoreService.getArray(this.flatSteps).filter(s => s.place && s.place.lon == path.original_location[0] && s.place.lat == path.original_location[1] || s.crossingPoint && s.crossingPoint.lon == path.original_location[0] && s.crossingPoint.lat == path.original_location[1]).first();
                    
                    // Reset old distances/times
                    delete step.travelTime;
                    delete step.travelDistance;
                    delete step.totalTravelDistance;
                    delete step.totalTravelDistanceFormatted;
                    delete step.travelTimeFormatted;
                    delete step.travelDistanceFormatted;
                    delete step.timeFromPrevious;
                    delete step.timeFromPreviousFormatted;
                    delete step.distanceFromPrevious;
                    delete step.distanceFromPreviousFormatted;
                    
                    delete step.timeToNextFormatted;
                    delete step.distanceToNextFormatted;
                    
                    if(path.timeToNext && path.distanceToNext) {
                        step['timeToNext'] = Number(path.timeToNext.toFixed(0));
                        step['timeToNextFormatted'] = this.getTravelTimeFormatted(Number(path.timeToNext.toFixed(0)));
                        step['distanceToNext'] = Number(path.distanceToNext.toFixed(2));
                        step['distanceToNextFormatted'] = this.getTravelDistanceFormatted(Number(path.distanceToNext.toFixed(2)));
                    }
                    
                    if(step.isDeparture) {
                        this.trip.departure = step;
                    }
                    
                    if(step.isArrival) {
                        this.trip.arrival = step;
                    }
                    
                    if(step.isNight) {
                        this.trip.nights.push(step);
                    }
                    
                    if(!step.isDeparture && !step.isArrival && !step.isNight) {
                        this.dispatchStepInDays(step, optimalRoutes[routeIndex].dayIndex);
                    }
                }
            }
            
            this.showOptimizationInfos();
            this.optimized = true;
            this.allowSave();
            
            this.hideOptimizationOverlay();
        },
        
        // TO DO : move to worker
        async findOptimalPathAsync(data) {
            return new Promise((resolve, reject) => {
                this.worker.onmessage = function(e) {
                    resolve(e.data);
                };
                this.worker.postMessage(data);
            });
        },
        
        getTravelTimeFormatted(timeToNext) {
            if(timeToNext >= 60) {
                let hours = Math.floor(timeToNext / 60);
                let minutes = timeToNext % 60;
                return hours + 'h ' + minutes + 'min';
            }
            
            if(timeToNext == 0) {
                return '< 1 min';
            }
            
            return timeToNext + ' min';
        },
        
        getTravelDistanceFormatted(distanceToNext) {
            if(distanceToNext < 1) {
                return (distanceToNext * 1000) + ' m';
            }
            
            return distanceToNext.toFixed(2) + ' km';
        },
        
        hideOptimizationInfos() {
            $('.travel-optimization-infos, .travel-optimization-infos-start').addClass('hidden');
        },
        
        showOptimizationInfos() {
            $('.travel-optimization-infos, .travel-optimization-infos-start').removeClass('hidden');
        },
        
        removeOptimizationInfos(dayIndex) {
            // REMOVE ALL DAY STEPS OPTIMIZATION
            for(let stepIndex = 0; stepIndex < this.trip.days[dayIndex].length; stepIndex++) {
                if(typeof this.trip.days[dayIndex][stepIndex] != 'undefined') {
                    delete this.trip.days[dayIndex][stepIndex].timeToNext;
                    delete this.trip.days[dayIndex][stepIndex].distanceToNext;
                }
            }
        },
        
        // #endregion
        
        // #region MAP
        
        sendTripMarkersToMap() {
            console.log('sendTripMarkersToMap', this.trip);
            this.tripSteps = JSON.stringify(this.trip);
        },
        
        goToExploreMap() {
            this.$router.push({ name: 'map', params: { mode: 'explore' } });
        },
        
        // #endregion
        
        // #region DEPARTURE ARRIVAL
        
        addDeparture() {
            this.$router.push({ name: 'map', params: { 
                mode: 'explore',
                departureNeeded: true,
                arrivalNeeded: false,
                nightNeeded: null,
            }});
        },
        
        deleteDeparture() {
            delete this.trip.departure;            
            this.optimized = false;
            this.allowSave();
        }, 
        
        addArrival() {
            this.$router.push({ name: 'map', params: { 
                mode: 'explore',
                departureNeeded: false,
                arrivalNeeded: true,
                nightNeeded: null,
            }});
        },
        
        deleteArrival() {
            delete this.trip.arrival;            
            this.optimized = false;
            this.allowSave();
        },
        
        // #endregion
        
        // #region UTILS
        
        getInternationalPlaceName(place) {
            return place.name_international && place.name_international[this.$root.language] ? place.name_international[this.$root.language] ?? place.name : place.name;
        },
        
        setInternationalPlaceName(days) {
            for(var i = 0; i < days.length; i++) {
                let currentDay = days[i];
                
                for(var j = 0; j < currentDay.length; j++) {
                    let step = currentDay[j];
                    
                    if(step.place) {
                        step.place.name = this.getInternationalPlaceName(step.place);
                    }
                    
                    else {
                        let locationCity = step.crossingPoint.address.municipality ?? step.crossingPoint.address.city;
                        step.crossingPoint.name = 'Crossing point - ' + locationCity;
                    }
                }
            }
        },
        
        // #endregion
    }
};
</script>

<style scoped>
@import "../assets/scss/trip.scss";
</style>